import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useQuery, useQueryClient } from 'react-query'

import api, { QUERY_KEYS } from '~api'
import { ROUTES } from '~routes'
import { Loader } from '~globalComponents/Loader'

const queryFn = ({ queryKey }) => {
  const [, { params }] = queryKey
  return api
    .get(`/${QUERY_KEYS.CALLBACK_SSO}`, { params })
    .then((res) => res.data?.token)
}

export const CallbackSSO = () => {
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const { state, code } = qs.parse(search)
  const navigate = useNavigate()
  const routeToLogin = sessionStorage.getItem('routeToLogin')

  useQuery([QUERY_KEYS.CALLBACK_SSO, { params: { state, code } }], queryFn, {
    onSuccess: (token) => {
      if (!token) return
      localStorage.setItem('token', token)
      queryClient.invalidateQueries(QUERY_KEYS.ACCOUNT)
      navigate(routeToLogin || ROUTES.HOME)
    },

    onError: () => {
      navigate(`${ROUTES.ERROR}?type=no_access`)
    },
    cacheTime: 0,
  })

  return <Loader variant="fullScreen" />
}
