import React, { useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import qs from 'query-string'

import api, { QUERY_KEYS } from '~api'
import { ROUTES } from '~routes'
import { Loader } from '~globalComponents/Loader'
import '../Login/style.scss'

const queryFn = ({ queryKey }) => {
  const [, { params }] = queryKey
  return api.get(`/${QUERY_KEYS.IDP}`, { params }).then((res) => res?.data)
}

export const IDP = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const parsedSearch = qs.parse(search)

  const idpRef = useRef()

  const { data, isLoading, isSuccess } = useQuery(
    [
      QUERY_KEYS.IDP,
      { params: { ...parsedSearch, token: localStorage.getItem('token') } },
    ],
    queryFn,
    {
      onError: () => {
        navigate(ROUTES.LOGIN)
      },
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (isSuccess && data) {
      const form = idpRef.current?.querySelector('form')
      form?.submit()
    }
  }, [isSuccess, data])

  return isLoading ? (
    <Loader variant="fullScreen" />
  ) : (
    <div ref={idpRef} dangerouslySetInnerHTML={{ __html: data }} />
  )
}
