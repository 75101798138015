import React from 'react'
import cn from 'classnames'

import * as s from './Loader.module.scss'

export const Loader = ({ variant, color }) => {
  return (
    <div
      className={cn('loader', s.loader, {
        [s[variant]]: variant,
        [s[color]]: color,
      })}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80">
        <path
          fill="url(#a)"
          fillRule="evenodd"
          d="M0 42.6A40 40 0 0 1 5.5 20l20.3 11.7A16.5 16.5 0 0 0 44.3 56l6 22.7a40 40 0 0 1-50.2-36Z"
          clipRule="evenodd"
        />
        <path
          fill="url(#b)"
          fillRule="evenodd"
          d="M0 42.6A40 40 0 0 1 5.5 20l20.3 11.7A16.5 16.5 0 0 0 44.3 56l6 22.7a40 40 0 0 1-50.2-36Z"
          clipRule="evenodd"
        />
        <path
          fill="url(#c)"
          fillRule="evenodd"
          d="m56 44.2 22.7 6.1a40 40 0 0 1-28.4 28.3l-6-22.7c5.7-1.5 10.1-6 11.6-11.7Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M5.4 20a40 40 0 1 0 73.2 9.6l-3.8 1a36 36 0 1 1-66-8.6l-3.4-2Zm6.9 4a32 32 0 1 0 58.6 7.7l-3.7 1a28.2 28.2 0 1 1-51.6-6.8L12.3 24ZM16 39.2A24 24 0 0 1 19.2 28l3.3 1.9a20.2 20.2 0 1 0 37 4.9l3.7-1A24 24 0 1 1 16 39.2Z"
          clipRule="evenodd"
        />
        <defs>
          <linearGradient
            id="a"
            x1="27.5"
            x2="44.1"
            y1="49"
            y2="51.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41C499" stopOpacity=".6" />
            <stop offset="1" stopColor="#41C499" stopOpacity=".4" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="15.9"
            x2="30.1"
            y1="23.5"
            y2="52.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41C499" />
            <stop offset="1" stopColor="#41C499" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="48.5"
            x2="56.8"
            y1="58.5"
            y2="49.1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41C499" stopOpacity=".4" />
            <stop offset="1" stopColor="#41C499" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
