/* eslint-disable global-require */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import qs from 'query-string'

import { queryClient } from '~api'
import App from './App'
import * as serviceWorker from './serviceWorker'

const { no_animation: bootIntercom } = qs.parse(window.location.search)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID}
        apiBase={process.env.REACT_APP_INTERCOM_BASE_URL}
        autoBoot={bootIntercom !== '1'}
      >
        <App />
      </IntercomProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
)

if (process.env.NODE_ENV === 'production') {
  require('./FS_script')
  require('./Decibel_script')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
