import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useTooltip, useHover } from '~hooks'
import Icon from '~globalComponents/Icon'

import * as s from './style.module.scss'

const Tooltip = ({
  as,
  show,
  className,
  hasTooltip,
  variant,
  title,
  placement,
  size,
  content,
  followMouse,
  withIcon,
  icon = 'info_outlined',
  iconSize,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [animationTooltip, setAnimationTooltip] = useState(false)
  const { tooltipRef, handleMouseMove, coords } = useTooltip({
    isBottom: placement === 'followMouseBottom',
  })
  const [hoverRef, isHovered] = useHover()

  const tooltipVisibility = show || (isHovered && hasTooltip)

  useEffect(() => {
    const tooltipTimer = setTimeout(
      () => {
        setShowTooltip(tooltipVisibility)
      },
      tooltipVisibility ? 0 : s.delay
    )

    const animationTimer = setTimeout(
      () => {
        setAnimationTooltip(tooltipVisibility)
      },
      tooltipVisibility ? 10 : 0
    )

    return () => {
      clearTimeout(tooltipTimer)
      clearTimeout(animationTimer)
    }
  }, [tooltipVisibility])

  const isFullSize = ['date', 'chart', 'brand', 'search', 'exports'].includes(
    variant
  )

  const tooltipPosition = followMouse
    ? {
        position: 'fixed',
        ...coords,
        visibility: coords.left ? 'visible' : 'hidden',
      }
    : { position: 'absolute' }

  return React.createElement(
    as,
    {
      className: cn(
        s.wrapper,
        [s[variant]],
        {
          [s.fullSize]: isFullSize,
          [s.posStatic]: followMouse,
        },
        className
      ),
      onMouseMove: handleMouseMove,
      ref: hoverRef,
    },
    <>
      {children}
      {withIcon && (
        <span className={s.marker}>
          <Icon name={icon} size={iconSize} />
        </span>
      )}
      {showTooltip && (
        <div
          className={cn(
            s.tooltip,
            {
              [s[placement]]: !followMouse,
              [s.show]: animationTooltip,
            },
            s[size]
          )}
          ref={tooltipRef}
          style={tooltipPosition}
        >
          {!!title && <p className={s.title}>{title}</p>}
          <div>{content}</div>
        </div>
      )}
    </>
  )
}

Tooltip.defaultProps = {
  as: 'div',
  hasTooltip: true,
  title: '',
  variant: 'default',
  size: 'lg',
  placement: 'bottom',
  children: null,
  followMouse: false,
  withIcon: false,
  iconSize: 20,
  content: '',
}

Tooltip.propTypes = {
  as: PropTypes.string,
  hasTooltip: PropTypes.bool, // Used in Attribute table to show on specific cells
  variant: PropTypes.oneOf([
    'default',
    'chart',
    'date',
    'brand',
    'search',
    'nav',
    'exports',
  ]),
  size: PropTypes.oneOf(['sm', 'lg', 'xl', 'xxl', 'fitContent', 'maxContent']),
  title: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom',
    'bottomEnd',
    'bottomRight',
    'bottomStart',
    'left',
    'right',
    'brandTop',
    'brandBottom',
    'followMouseBottom',
  ]),
  content: PropTypes.node,
  followMouse: PropTypes.bool,
  withIcon: PropTypes.bool,
  iconSize: PropTypes.number,
  children: PropTypes.node,
}

export default Tooltip
