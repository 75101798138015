import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '~api'
import { ROUTES } from '~routes'

export const useAccount = () => {
  const isAlreadyTracked = useRef(false)
  const navigate = useNavigate()

  return useQuery(QUERY_KEYS.ACCOUNT, {
    enabled: !!localStorage.getItem('token'),
    staleTime: 5 * 60 * 1000,
    onSuccess: (accountInfo) => {
      if (isAlreadyTracked.current) return

      if (process.env.NODE_ENV === 'production' && accountInfo?.account_id) {
        const { account_id, name, email, type } = accountInfo
        window.FS.identify(account_id, {
          displayName: name,
          email,
          type,
          // TODO: Add your own custom user variables here, details at
          // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
          // reviewsWritten_int: 14
        })
        isAlreadyTracked.current = true
      }
    },
    onError: () => {
      localStorage.removeItem('token')
      navigate(ROUTES.LOGIN)
    },
  })
}
