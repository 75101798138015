// extracted by mini-css-extract-plugin
export var black = "#000007";
export var blue = "#2c60ad";
export var bodybg = "#f8f9fa";
export var brown = "#c6816c";
export var cyanb = "#17a2b8";
export var darkgreen = "#75a729";
export var flatblue = "#6ca7ff";
export var gray10 = "#f5f8fa";
export var gray100 = "#131719";
export var gray20 = "#e9edf0";
export var gray200 = "#e9ecef";
export var gray30 = "#d7dce0";
export var gray300 = "#596066";
export var gray35 = "#d5dbe0";
export var gray40 = "#c4cacf";
export var gray400 = "#343a40";
export var gray45 = "#afb7bd";
export var gray50 = "#98a0a6";
export var gray500 = "#abb4ba";
export var gray60 = "#80868c";
export var gray600 = "#1a1f21";
export var gray70 = "#656d73";
export var gray80 = "#424a4f";
export var gray90 = "#292f33";
export var green = "#94d82d";
export var green10 = "#ddf4ec";
export var green100 = "#1c5945";
export var green20 = "#b6e8d8";
export var green30 = "#8fdcc3";
export var green40 = "#68d0ae";
export var green50 = "#41c499";
export var green60 = "#36ad86";
export var green70 = "#2e9372";
export var green80 = "#26795e";
export var green90 = "#1e5f4a";
export var greentext = "#013506";
export var lightblue = "#3bc9db";
export var milky = "#f7f9fa";
export var negative10 = "#ede7f6";
export var negative30 = "#b39ddb";
export var negative40 = "#9575cd";
export var negative50 = "#7e57c2";
export var negative60 = "#7949ce";
export var negative70 = "#673ab7";
export var orange = "#febe10";
export var orange10 = "#ffeee8";
export var orange20 = "#fed6c8";
export var orange30 = "#fcaf93";
export var orange40 = "#f49a79";
export var orange50 = "#fa7b4d";
export var orange60 = "#f56b38";
export var orange70 = "#e36334";
export var orangetext = "#461604";
export var pink = "#f783ac";
export var pinkb = "#e83e8c";
export var pinkgold = "#ffa49e";
export var purple = "#a138e1";
export var redb = "#dc3545";
export var softmagenta = "#cc5de8";
export var softpurple = "#908ab5";
export var softred = "#ff6d6d";
export var teal = "#289c87";
export var toggle = "Toggle_toggle__KKYyX";
export var toggleText = "Toggle_toggleText__W2SgD";
export var violet = "#8d84fd";
export var white = "#fff";
export var yellow = "#fcc419";
export var yellowb = "#ffc107";