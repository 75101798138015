import React from 'react'

import { BACKUP_LOGO_NAME } from './constants'

export const importLogos = (r) =>
  r.keys().reduce(
    (acc, item, i) => ({
      ...acc,
      [item.replace(/\.\/|\..*/g, '')]: r.keys().map(r)[i],
    }),
    {}
  )

export const brandLogos = importLogos(
  require.context('~img/brands/', false, /\.(png|jpe?g|svg)$/)
)

export const renderBrandLogo = ({
  brandLogos: brandLogosArray,
  brand,
  brandName,
}) => {
  const brandLogoPath = brandLogosArray[brandName]
  const backupLogoPath = brandLogosArray[BACKUP_LOGO_NAME]

  return <img src={brandLogoPath || backupLogoPath} alt={brand} aria-hidden />
}
