import React, { Fragment } from 'react'
import cn from 'classnames'

export const Radio = ({
  tabs,
  name,
  register,
  fieldLabel,
  variant,
  idPrefix = 'l',
}) => (
  <div
    className={cn('field field--radio', {
      [variant]: variant,
    })}
  >
    {fieldLabel && <div className="field_label">{fieldLabel}</div>}
    <div className="field_input field_input--radio">
      {tabs.map(({ value, label }) => {
        return (
          <Fragment key={value}>
            <input
              {...register(name)}
              type="radio"
              value={value}
              id={`${idPrefix}-${value}`}
            />
            <label htmlFor={`${idPrefix}-${value}`}>{label}</label>
          </Fragment>
        )
      })}
    </div>
  </div>
)
