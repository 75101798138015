import React from 'react'
import cn from 'classnames'
import { get } from 'lodash'

export const Input = ({
  register,
  rules,
  name,
  placeholder,
  errors,
  label,
  disabled,
  type,
  variant,
}) => (
  <div className={cn('field', { [variant]: variant })}>
    <div className="field_input">
      <input
        {...register(name, rules)}
        id={`l${name}`}
        type={type}
        placeholder={placeholder}
        className={cn('form-control', { error: get(errors, name) })}
        disabled={disabled}
      />

      <label htmlFor={`l${name}`} className="field_label">
        {label}
      </label>
    </div>
  </div>
)
