import axios from 'axios'
import { QueryClient } from 'react-query'
import EventEmitter from 'eventemitter3'

export const events = new EventEmitter.EventEmitter()

const api = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND ||
    `https://intelligence-${process.env.REACT_APP_ENV}.sense360eng.com/api/v1`,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null

    if (
      status === 401 ||
      (error.response?.data?.error &&
        error.response.data.error?.toLowerCase()?.includes('token'))
    ) {
      localStorage.removeItem('token')
    }

    return Promise.reject(error)
  }
)

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  // eslint-disable-next-line no-param-reassign
  if (token) config.headers.Authorization = `Bearer ${token}`

  return config
})

export const QUERY_KEYS = {
  DASHBOARDS: '/dashboards',
  DASHBOARD: '/dashboard',
  ACCOUNT: '/account',
  HOME: '/page/home',
  METRIC_CHART: 'metric_chart',
  USER_COMPETITORS: '/competitors',
  MARKET_PERFORMANCE: 'market_performance',
  COMPETITIVE_PERFORMANCE: 'competitive_performance',
  CUSTOMER_PROFILE: 'customer_profile',
  CUSTOMER_EXPERIENCE: 'customer_experience',
  PETCO_CUSTOMER_EXPERIENCE: 'petco_customer_experience',
  BRAND_PERCEPTION: 'brand_perception',
  CUSTOMER_MIGRATION: 'customer_migration',
  KPI_TRACKING: 'kpi_tracking',
  PUBLIC_MARKETING: 'page/marketing',
  SSO: 'sso',
  CALLBACK_SSO: 'sso/callback',
  IDP: 'idp',
  SELECT_BRAND: 'select',
  PASSWORD_INIT_RESET: '/password/init_reset',
  PASSWORD_TOKEN: '/password/token',
  PASSWORD_RESET: '/password/reset',
  INSIGHTS_BRIEFINGS: 'insights_briefings',
  SKELETON: 'skeleton',
  SUBSCRIPTIONS: 'subscriptions',
  UNSUBSCRIBE: 'unsubscribe',
  TOPLINE_DATA: '/get_topline_data',
  QUEUE: '/queue',
  SEND_REPORTS: '/send_reports',
  REPORTS: '/reports',
  REPORT: '/report',
}

// Define a default query function that will receive the query key
const defaultQueryFn = ({ queryKey }) => {
  const [endpoint, keys] = queryKey // !TODO refactor params in query keys

  return api
    .get(endpoint, { params: keys?.params })
    .then((res) =>
      res?.data?.error ? Promise.reject(res?.data?.error) : res?.data
    )
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false, // after getting error disable auto-retry
      queryFn: defaultQueryFn,
      staleTime: 5 * 60 * 1000,
    },
  },
})

export default api
