import React from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'

import Tooltip from '~globalComponents/Tooltip'
import { Option } from './Option'
import { SELECT_STYLES } from './constants'
import { ArrowIcon, SearchIcon } from './Icons'

export const Select = ({
  name,
  control,
  onChange,
  isShort,
  handleEditClick,
  rules,
  isDisabled,
  disabledMessage,
  withSearchIcon,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const props = {
          ...field,
          styles: SELECT_STYLES,
          isShort,
          handleEditClick,
          isDisabled,
          withSearchIcon,
          onChange: (e) => {
            field.onChange(e)
            if (onChange) onChange(e)
          },
          components: {
            Option,
            IndicatorsContainer: withSearchIcon ? SearchIcon : ArrowIcon,
          },
          ...rest,
        }

        return isDisabled && disabledMessage ? (
          <Tooltip
            variant="search"
            placement="bottomStart"
            content={disabledMessage}
          >
            <ReactSelect {...props} />
          </Tooltip>
        ) : (
          <ReactSelect {...props} />
        )
      }}
    />
  )
}
