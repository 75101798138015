/* eslint-disable default-param-last */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-bitwise */
import { DateTime } from 'luxon'
import qs from 'query-string'
import { omit, orderBy, get } from 'lodash'

import { METRIC_TYPES, TREND_ROUNDING_THREHOLD, LABELS } from '~constants'

export const capitalize = (t) => t.charAt(0).toUpperCase() + t.slice(1)

// export const checkStopWord = (words = [], str) =>
//   words.some((w) => str.includes(w))

export const last = (arr) =>
  arr && arr.length ? arr[arr.length - 1] : undefined

export const sortByOrder = (order, key) => (a, b) => {
  const lowerCaseOrder = order.map((item) => item.toLowerCase())
  const firstItem = key ? a[key] : a
  const secondItem = key ? b[key] : b
  return (
    lowerCaseOrder.indexOf(firstItem.toLowerCase()) -
    lowerCaseOrder.indexOf(secondItem.toLowerCase())
  )
}

export const round = (n, decimals = 0, withZeroDecimals) => {
  const value = Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`)
  return withZeroDecimals ? value.toFixed(decimals) : value
}

export const isObject = (val) => val !== null && typeof val === 'object'

// eslint-disable-next-line default-param-last
export const formatNum = (
  val,
  isPercent = true,
  decimals,
  withZeroDecimals
) => {
  const rounded = !isPercent ? round(val, decimals, withZeroDecimals) : val
  const absRounded = Math.abs(rounded)

  switch (true) {
    case absRounded >= 10 ** 12: {
      const t = round(rounded / 10 ** 12, decimals, withZeroDecimals)
      return { number: t, abbr: 'T', full: `${t}T` }
    }
    case absRounded >= 10 ** 9: {
      const b = round(rounded / 10 ** 9, decimals, withZeroDecimals)
      return { number: b, abbr: 'B', full: `${b}B` }
    }
    case absRounded >= 10 ** 6: {
      const m = round(rounded / 10 ** 6, decimals, withZeroDecimals)
      return { number: m, abbr: 'M', full: `${m}M` }
    }
    case absRounded >= 10 ** 3: {
      const k = round(rounded / 10 ** 3, decimals, withZeroDecimals)
      return { number: k, abbr: 'K', full: `${k}K` }
    }
    default: {
      const u = round(
        rounded * (isPercent ? 100 : 1),
        decimals,
        withZeroDecimals
      )
      return { number: u, abbr: '', full: u }
    }
  }
}

export const dateHeader = (period, date, isLcase = true) => {
  const ldate = DateTime.fromISO(date)

  switch (period) {
    case 'Week':
      return `${isLcase ? period.toLowerCase() : period} ending ${ldate
        .plus({ days: 6 })
        .toFormat('L/d/yyyy')}`
    case 'Month':
      return `in ${ldate.toFormat('LLLL yyyy')}`
    case 'Quarter':
      return `in Q${ldate.toFormat('q yyyy')}`
    default:
      return false
  }
}

export const rgb2hex = ([r, g, b]) =>
  ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)

export const hexToRGB = (hex) => {
  let alpha = false
  let h = hex.slice(hex.startsWith('#') ? 1 : 0)
  if (h.length === 3) h = [...h].map((x) => x + x).join('')
  else if (h.length === 8) alpha = true
  h = parseInt(h, 16)
  return `${(alpha ? 'a' : '') + (h >>> (alpha ? 24 : 16))}, ${
    (h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)
  }, ${(h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)}${
    alpha ? `, ${h & 0x000000ff}` : ''
  }`
}

export const getPoPLabel = (value, isNumber, hasPlaceholder) => {
  if (value == null && hasPlaceholder) return '—'

  const popValue = round(isNumber ? value : value * 100, 1)
  const popStart = popValue > 0 ? '+' : ''
  const popEnd = isNumber ? '' : 'pp '
  return `${popStart}${popValue} ${popEnd}`
}

export const formatObjectKey = (key) =>
  key?.toLowerCase().replace(/[^a-z]/g, '_')

export const getQuartersTitle = (startDate, endDate) => {
  const start = DateTime.fromISO(startDate).toFormat('Qq yyyy')
  const end = DateTime.fromISO(endDate).toFormat('Qq yyyy')
  return `${start} - ${end}`
}

export const buildPath = (
  pathName,
  props,
  parsedSearch,
  omitParams = ['page']
) => {
  const queryURLParams = qs.stringify({
    ...omit(parsedSearch, omitParams),
    ...props,
  })
  return `${pathName}?${queryURLParams}`
}

export const getTrendChangeTitle = (trend, ending = 'change') => {
  const prefix = trend === '2019' ? 'vs ' : ''
  return `${prefix}${trend} ${ending}`
}

export const getValidateObj = (rules) =>
  rules.reduce((acc, { key, rule }) => {
    acc[key] = (value) =>
      Number.isInteger(rule) ? value.length >= rule : rule.test(value)
    return acc
  }, {})

export const isDataDifferencePositive = (data, benchmark) =>
  data - benchmark > 0

export const isMoreThanTrendRoundingThrehold = (number) =>
  Math.abs(number) > TREND_ROUNDING_THREHOLD

export const displayPercent = (
  number,
  decimals = 0,
  showPlusSign,
  suffix = '%',
  withZeroDecimals
) => {
  const rounded = round(number * 100, decimals, withZeroDecimals)
  const prefix = showPlusSign && rounded > 0 ? '+' : ''

  return `${prefix}${rounded}${suffix}`
}

export const getMetricSortOrder = (name, metricTypes, isScoreMode) => {
  const key = formatObjectKey(name)
  const metricType = metricTypes?.[key]

  switch (true) {
    case metricType === METRIC_TYPES.NPS:
      return 0
    case isScoreMode && metricType === METRIC_TYPES.SCORE_5:
    case isScoreMode && metricType === METRIC_TYPES.SCORE_10:
      return 1
    default:
      return 2
  }
}

export const sortByMetricType = ({ data, key, metricTypes, isScoreMode }) =>
  [...data].sort((a, b) => {
    const aMetricOrder = getMetricSortOrder(a[key], metricTypes, isScoreMode)
    const bMetricOrder = getMetricSortOrder(b[key], metricTypes, isScoreMode)
    return aMetricOrder < bMetricOrder ? -1 : 1
  })

export const getTooltipDateTitle = (start, end) =>
  `Q${DateTime.fromISO(start).toFormat('q yyyy')} - Q${DateTime.fromISO(
    end
  ).toFormat('q yyyy')}`

export const getIsScoreType = (metricType) =>
  metricType === METRIC_TYPES.SCORE_5 || metricType === METRIC_TYPES.SCORE_10

export const getMarket = ({ currentMarket, marketBenchmark }) => {
  const isMarketBenchmark = currentMarket === LABELS.MARKET_BENCHMARK
  return isMarketBenchmark ? marketBenchmark : currentMarket
}

export const getMarketDescription = ({
  start = 'Your Market Benchmark consists of the following market categories: ',
  submarkets,
  currentMarket,
}) => {
  if (!submarkets?.length) return null

  if (submarkets.length === 1) return `${start} ${submarkets[0]}`

  const tooltipSubmarkets = submarkets.filter(
    (market) => market !== currentMarket
  )
  const submarketsSubstr = `${tooltipSubmarkets.slice(0, -1).join(', ')} and ${
    tooltipSubmarkets[tooltipSubmarkets?.length - 1]
  }`
  return `${start} ${submarketsSubstr}.`
}

// sort order: brand, benchmark, other in alphabetical order
export const sortChartData = ({
  data,
  brand,
  dottedItems = [],
  key = 'name',
}) =>
  orderBy(
    data,
    [
      (v) => {
        const value = get(v, key)
        return value.includes(brand)
      },
      (v) => {
        const value = get(v, key)
        return value.includes('Benchmark') || dottedItems.includes(value)
      },
      key,
    ],
    ['desc', 'desc', 'asc']
  )

export const getSolidGaugeChartValue = (value, isNumber) => {
  if (value == null) return null
  return isNumber ? value : value * 100
}
