import React, { useState } from 'react'
import cn from 'classnames'

import { Select } from '../../Select'

export const SelectBlock = ({
  name,
  id,
  label,
  options,
  control,
  rules,
  error,
  getValues,
  onMenuOpen,
  onMenuClose,
  isSearchable = true,
  variant,
  selectAdditionalProps,
  isDisabled,
  disabledMessage,
  withSearchIcon,
}) => {
  const [isOpen, setIsOpen] = useState(null)

  const handleOpen = () => {
    setIsOpen(true)
    if (onMenuOpen) onMenuOpen()
  }

  const handleClose = () => {
    setIsOpen(false)
    if (onMenuClose) onMenuClose()
  }

  return (
    <div className={cn('field field--select', variant)}>
      <label
        htmlFor={id}
        className={cn('field_label', {
          'field_label--error': error,
          is_empty: !isOpen && !getValues(name)?.value,
        })}
      >
        {label}
      </label>
      <div className="field_input field_input--search">
        <Select
          control={control}
          id={id}
          name={name}
          rules={rules}
          hasError={error}
          options={options || []}
          onMenuOpen={handleOpen}
          onMenuClose={handleClose}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          disabledMessage={disabledMessage}
          withSearchIcon={withSearchIcon}
          {...selectAdditionalProps}
        />
      </div>
      <div className={cn('field_error', { visible: error })}>
        {error?.message}
      </div>
    </div>
  )
}
