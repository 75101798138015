import * as palette from '~styles/palette.scss'

export const TREND_ROUNDING_THREHOLD = 0.00049

export const CACHE_TIME = 60 * 60 * 1000

export const MODULES = {
  KPI_TRACKING: {
    title: 'KPI Perfomance',
    key: 'kpi_tracking',
    value: 'KPI Tracking',
  },
}

export const REPORTS_PER_PAGE = 8

export const EXPORT_STATUS = {
  DOWNLOADED: 'downloaded',
  READY: 'ready',
  PROCESSING: 'processing',
  FAILED: 'failed',
}

export const METRIC_TYPES = {
  PERCENT: 'percent',
  SCORE_5: 'score5',
  SCORE_10: 'score10',
  NPS: 'nps',
}

export const LABELS = {
  COMPETITIVE_SET: 'Competitive Set',
  MARKET_BENCHMARK: 'Market Benchmark',
  TOTAL: 'Total',
}

export const EMPTY_SELECT_VALUE = { label: '', value: '' }

export const PERIOD_TABS = [
  { label: 'Week', value: 'Week' },
  { label: 'Month', value: 'Month' },
  { label: 'Quarter', value: 'Quarter' },
]

export const SERIES_COLORS = [
  palette.green50,
  palette.softred,
  palette.purple,
  palette.lightblue,
  palette.green,
  palette.yellow,
  palette.violet,
  palette.flatblue,
  palette.pink,
  palette.softmagenta,
  palette.brown,
  palette.darkgreen,
  palette.blue,
  palette.softpurple,
  palette.pinkgold,
  palette.teal,
]

export const SERVE_SERIES_COLORS = [palette.gray60]

export const CHART_WITHOUT_ANIMATION = {
  plotOptions: {
    series: {
      animation: false,
    },
  },
}

export const CHARTS_THEME = {
  global: {
    useUTC: false,
  },
  chart: {
    selectionMarkerFill: 'rgba(54, 173, 134, 0.3)',
    time: {
      timezoneOffset: 5 * 60,
    },
    style: {
      fontFamily: 'inherit',
    },
  },
  title: false,
  // colors: [palette.green50, palette.gray500],
  plotOptions: {
    spline: {
      // color: palette.negative50,
      lineWidth: 3,
      shadow: {
        color: palette.white,
        offsetX: 0,
        offsetY: 0,
        width: 4,
        opacity: 1,
      },
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        lineWidth: 1,
        lineColor: palette.white,
        // fillColor: palette.green60,
      },
      states: {
        hover: {
          lineWidthPlus: 0,
          halo: {
            attributes: {
              // fill: palette.negative50,
              stroke: palette.white,
              'stroke-width': 2,
            },
            opacity: 1,
            size: 8,
          },
        },
      },
    },
    column: {
      borderRadius: 2,
      states: {
        hover: {
          // opacity: 1,
          // color: palette.green60,
          brightness: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  xAxis: {
    // crosshair: true,
    lineColor: palette.gray200,
    labels: {
      autoRotation: false,
      style: {
        color: palette.gray500,
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: 500,
      },
    },
  },
  yAxis: {
    title: false,
    gridLineColor: palette.gray200,
    // gridLineDashStyle: 'longdash',
    labels: {
      style: {
        color: palette.gray500,
        lineHeight: '16px',
        fontSize: '12px',
      },
      format: '{value} %',
    },
  },
  legend: {
    itemDistance: 24,
    useHTML: true,
    align: 'left',
    symbolPadding: 8,
    itemStyle: {
      color: palette.gray600,
      fontSize: '14px',
      fontWeight: 'normal',
      verticalAlign: 'middle',
    },
  },
  tooltip: {
    shadow: false,
    borderWidth: 0,
    padding: 0,
    backgroundColor: false,
    valueSuffix: ' %',
    style: {
      fontSize: '14px',
      color: palette.gray300,
      padding: '13px 19px',
    },
    shared: true,
    useHTML: true,
  },
  credits: {
    enabled: false,
  },
}

export const LEGEND_DBCLICK = {
  legend: {
    useHTML: false,
    className: 'legend--dbclick',
    itemEvents: {
      // show all series if no visible
      click() {
        const allHidden = this.chart.series.every(({ visible }) => !visible)
        if (allHidden) {
          this.chart.series.forEach((serie) => serie.show())
        }
      },

      // hide all except clicked series
      dblclick() {
        this.chart.series.forEach((serie, i) =>
          i === this.index ? serie.show() : serie.hide()
        )
      },
    },
  },
}

export const EVENT_KEYS = {
  REPORT_EXPORT_SUCCESS: 'report_export_success',
  REPORT_EXPORT_ERROR: 'report_export_error',
}
