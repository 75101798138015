import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import Icon from '../../Icon'

export const IconButton = ({
  iconComponent,
  iconHref,
  iconSize = 24,
  fluid = false,
  children,
  className,
  to,
  ...buttonAttrs
}) => {
  const renderContent = () => (
    <>
      <span>{children}</span>
      {iconComponent || <Icon name={iconHref} size={iconSize} />}
    </>
  )

  const props = {
    className: cn(
      'btn btn-outline-gray btn-icon',
      { 'btn-fluid': fluid },
      className
    ),
    ...buttonAttrs,
  }

  return to ? (
    <Link to={to} {...props}>
      {renderContent()}
    </Link>
  ) : (
    <button type="button" {...props}>
      {renderContent()}
    </button>
  )
}
