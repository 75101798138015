import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '~api'
import { getNotAvailableCompetitors } from '~utils/dashboardHeader'

export const useNotAvailableCompetitors = ({
  benchmark,
  brands,
  key = 'notAvailableCompetitors',
}) => {
  const { data, isFetching, refetch } = useQuery(
    [QUERY_KEYS.USER_COMPETITORS, key],
    { enabled: false }
  )

  const notAvailableCompetitors = useMemo(
    () =>
      getNotAvailableCompetitors({
        benchmark,
        competitors: data?.competitors,
        brands,
      }),
    [benchmark, data?.competitors, brands]
  )

  return {
    notAvailableCompetitors: !isFetching && notAvailableCompetitors,
    competitors: data?.competitors,
    refetchCompetitors: refetch,
  }
}
