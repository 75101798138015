import React from 'react'
import cn from 'classnames'
import { isDataDifferencePositive } from '~utils'
import { getBadgeClass } from './utils'
import Icon from '../../Icon'

export const Badge = ({
  isIndex,
  data,
  text,
  icon,
  benchmark,
  trend,
  isColorInverted,
  variant,
  className,
  children,
}) => {
  const dataClass = data && getBadgeClass(data, benchmark, isColorInverted)

  return (
    <span
      className={cn('badge badge-pill', {
        [className]: className,
        [`badge-${variant}`]: variant,
        [dataClass]: dataClass,
      })}
    >
      {children}

      {icon && <Icon name={icon} size={16} />}

      {text || (
        <>
          {isIndex ? 'INDEX' : trend}
          {isDataDifferencePositive(data, benchmark) ? (
            <Icon name={isIndex ? 'plus' : 'arrow_up'} size={16} />
          ) : (
            <Icon name={isIndex ? 'minus' : 'arrow_down'} size={16} />
          )}
        </>
      )}
    </span>
  )
}
