import React, { useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'query-string'
import classNames from 'classnames'

import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import api, { QUERY_KEYS } from '~api'
import logo from '~img/logo.svg'
import { ROUTES } from '~routes'
import { Loader } from '~globalComponents/Loader'
import Footer from '~globalComponents/Footer'
import { SubmitButton } from '~globalComponents/UI'
import { useSSO } from './hooks'
import '../Login/style.scss'

const queryFn = ({ queryKey }) => {
  const [, { params }] = queryKey
  return api.get(`/${QUERY_KEYS.SSO}`, { params }).then((res) => res?.data)
}

export const LoginSSO = () => {
  const { search } = useLocation()
  const params = qs.parse(search)

  useSSO(QUERY_KEYS.SSO, !!params.company)

  const [company, setCompany] = useState(params.company)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { company }, shouldUseNativeValidation: false })

  const { isLoading, isError } = useQuery(
    [QUERY_KEYS.SSO, { params: { company } }],
    queryFn,
    {
      enabled: !!company,
      onSuccess: (res) => {
        if (res?.redirect) {
          window.location = res.redirect
        }
      },

      cacheTime: 0,
    }
  )

  const submitForm = useCallback((formValues) => {
    setCompany(formValues.company)
  }, [])

  return params.company ? (
    <Loader variant="fullScreen" />
  ) : (
    <div className="wrap wrap--service">
      <section className="login login_medallia">
        <div className="login_logo">
          <img src={logo} alt="Sense 360" />
        </div>
        <div className="login_box">
          <h2 className="title" style={{ textAlign: 'center' }}>
            Sign in with Medallia
          </h2>
          <div className="form_notify form_notify--error">
            {isError && (
              <>
                Unable to log in. Please contact
                <a
                  href="mailto:sense360-support@medallia.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="error--email"
                >
                  sense360-support@medallia.com
                </a>
                .
              </>
            )}
          </div>
          <form
            onSubmit={handleSubmit(submitForm)}
            className="form form--login"
          >
            <div className="field field--text">
              <div className="field_input">
                <input
                  {...register('company', { required: true })}
                  id="fcompany"
                  placeholder="Name of your company"
                  type="text"
                  className={classNames('form-control', {
                    error: Object.keys(errors).includes('company'),
                  })}
                  autoFocus
                />
                <label htmlFor="fcompany" className="field_label">
                  Name of your company
                </label>
              </div>
            </div>

            <div className="form_bottom">
              <SubmitButton title="Submit" isLoading={isLoading} />
            </div>
          </form>

          <div className="login_box_bottom">
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </div>

        <div className="login_bottom">
          <a
            href="https://sense360.com/contact"
            target="_blank"
            rel="noreferrer noopener"
          >
            Want to sign-up or see a demo?
          </a>
        </div>
      </section>
      <Footer hasHelpCenter={false} />
    </div>
  )
}
