import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import qs from 'query-string'
import cn from 'classnames'

import api, { QUERY_KEYS } from '~api'
import { EMAIL_RULE } from '~regExp'

import logo from '~img/logo.svg'
import { ROUTES } from '~routes'
import Footer from '~globalComponents/Footer'
import { SubmitButton } from '~globalComponents/UI'

import '../Login/style.scss'
import './style.scss'

const initReset = (email) =>
  api
    .post(QUERY_KEYS.PASSWORD_INIT_RESET, qs.stringify({ email }))
    .then((res) => res.data)

export const PasswordInitReset = () => {
  const { search } = useLocation()
  const params = qs.parse(search)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: false })

  const {
    mutate: initResetMutation,
    data: initResetData,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(initReset)

  const submitForm = ({ user }) => initResetMutation(user)

  return (
    <div className="wrap wrap--service">
      <section className="login login_medallia">
        <div className="login_logo">
          <img src={logo} alt="Sense 360" />
        </div>
        <div className="login_box">
          <h2 className="title" style={{ textAlign: 'center' }}>
            Reset My Password
          </h2>
          {isError || isSuccess ? (
            <div className="init_reset__result_message">
              {initResetData?.msg || error?.response.data.error}
            </div>
          ) : (
            <>
              <div
                className={cn('form_notify', {
                  'form_notify--error': params?.error,
                })}
              >
                {params?.error ||
                  'Enter the email associated with your account below and we’ll email you instructions to reset your password'}
              </div>
              <form
                onSubmit={handleSubmit(submitForm)}
                className="form form--login"
                noValidate
              >
                <div className="field field--text">
                  <div className="field_input">
                    <input
                      {...register('user', {
                        required: 'Required',
                        pattern: {
                          value: EMAIL_RULE,
                        },
                      })}
                      type="email"
                      id="rEmail"
                      className={cn('form-control', {
                        error: Object.keys(errors).includes('user'),
                      })}
                      placeholder="Email"
                    />
                    <label htmlFor="rEmail" className="field_label">
                      Email
                    </label>
                  </div>
                </div>

                <div className="form_bottom">
                  <SubmitButton
                    title="Reset Password"
                    isLoading={isLoading}
                    iconProps={{
                      name: 'arrow_big',
                      style: { transform: 'scaleX(-1)' },
                    }}
                  />
                </div>
              </form>
            </>
          )}
          <div className="login_box_bottom">
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </div>
      </section>
      <Footer hasHelpCenter={false} />
    </div>
  )
}
